import { Box, Button, Typography } from "@mui/material";
import { useRef, useState } from "react";
import Papa from 'papaparse';
import matchfile from "../asset/survey_file/matched 1.csv"
import axios from "axios";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import { TreeTable } from "primereact/treetable";
import "../App.css"
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import CircularProgress from '@mui/material/CircularProgress';


export function Home() {
    const fileRef = useRef()
    const fileRef2 = useRef()
    const [filedata, setFileData] = useState('')
    const [secondFile, setSecondFile] = useState('')
    const [drop, setDrop] = useState(false)
    const [secondDrop, setsecondDrop] = useState(false)
    const [response, serResponse] = useState([])
    const [fileOneName, setFileOneName] = useState('')
    const [fileTwoName, setFileTwoName] = useState('')
    const [matched, setMatched] = useState([])
    const [isloading, setIsloading] = useState(false)
    const [iscloading, setIsCloading] = useState(false)

    const handleFiletoJson = (e) => {
        console.log("first")
        console.log(drop)
        drop == true ? setFileData(e.dataTransfer.files[0]) : setFileData(e.target.files[0])
        drop == true ? setFileOneName(e.dataTransfer.files[0].name) : setFileOneName(e.target.files[0].name)
        setDrop(false)
        // const fileInput = drop==true?e.dataTransfer.files[0]:e.target.files[0]
        // if(fileInput.name.endsWith('.csv')){
        //     const reader = new FileReader();
        //     reader.onload = (e) => {
        //         const fileContent = e.target.result;
        //         Papa.parse(fileContent, {
        //             header: true,
        //             complete: (result) => {
        //                 setFileData(result.data)
        //             },
        //             error: (error) => {
        //                 console.error("Error parsing CSV:", error);
        //               },
        //             });
        //       };
        //       setDrop(false)
        //       reader.readAsText(fileInput); 
        //     }
    }
    const handleSecondFileUpload = (e) => {
        console.log("second")
        // if(secondDrop==true?e.dataTransfer.files[0]:e.target.files[0].name.endsWith('.csv')){
        //     secondDrop==true?setSecondFile(e.dataTransfer.files[0]):setSecondFile(e.target.files[0])
        // }
        // const formData = new FormData();
        // formData.append("file_url", secondDrop==true?e.dataTransfer.files[0]:e.target.files[0]);
        // console.log(e.target.files[0])
        // setDDD(formData)
        secondDrop == true ? setSecondFile(e.dataTransfer.files[0]) : setSecondFile(e.target.files[0])
        secondDrop == true ? setFileTwoName(e.dataTransfer.files[0].name) : setFileTwoName(e.target.files[0].name)
        setsecondDrop(false)


    }
    const handleSubmit = async () => {
        setIsloading(true)
        const formData = new FormData()
        formData.append("file_two", filedata)
        formData.append("file_one", secondFile)
        const url = "https://demo-be.beltpower.arivu-iq.com/"
        //const url = "http://127.0.0.1:8002/"
        let response = await axios.post(url + "beltpower/file-upload/", formData)
        setIsloading(false)
        serResponse(response.data)
    }
    const handleCompare = async () => {
        setIsCloading(true)
        const payload = {
            system_file: fileOneName,
            match_file: fileTwoName
        }
        console.log(payload)
        const url = "https://demo-be.beltpower.arivu-iq.com/"
        //const url = "http://127.0.0.1:8002/"
        let response = await axios.post(url + "beltpower/match-data/", payload)
        const parsed = JSON.parse(response.data.data)
        setIsCloading(false)
        setMatched(parsed)
    }

    const handleDrop = (e) => {
        e.preventDefault();
        setDrop(true)
        handleFiletoJson(e)

    };
    const handleDragOver = (e) => {
        setDrop(true)
        e.preventDefault();
    };
    const handleDropsecond = (e) => {
        e.preventDefault();
        setsecondDrop(true)
        handleSecondFileUpload(e)

    };
    const handleDragOverSecond = (e) => {
        setsecondDrop(true)
        e.preventDefault();
    };
    
      const getBackgroundColor = (rowData) => {
        return rowData.Percentage>85 ? 'green' :rowData.Percentage>70&&rowData.Percentage<85 ? 'orange':"#F1F5F9";
      };
    const matchedRecords = (data) => {
        const matches = data.Matches
        return (


            <>
                <DataTable value={data.Matches} >
                    <Column style={{ width: "500px", height: "50px", padding: "12px", border: "1px solid #454545" }} field="ShortDesc" header="ShortDesc" />
                    <Column style={{ width: "500px", height: "50px", padding: "12px", border: "1px solid #454545" }} field="LongDesc" header="LongDesc" />
                    <Column style={{ width: "500px", height: "50px", padding: "12px", border: "1px solid #454545" }} field="PartNumber" header="PartNumber" />
                    <Column style={{ width: "500px", height: "50px",  border: "1px solid #454545" }} field="Percentage" header="Percentage"  body={(rowData) => (
          <div style={{ backgroundColor: getBackgroundColor(rowData),height:"100%",display:"flex",justifyContent:"center",alignItems:"center",color:"white",fontSize:"18px",fontWeight:"700" }}  >
            {rowData.Percentage.toFixed(2)>100?100.0:rowData.Percentage.toFixed(2)}
          </div>
        )}/>
                </DataTable>
            </>



        )



    }
    // console.log(matched[0].Matched_system_data)
    const [columnVisible, setColumneVisible] = useState([
        { field: "Item", header: "Item", visible: true },
        { field: "Description", header: "Description", visible: true },
        { field: "Manufacturer", header: "Manufacturer", visible: true },
        { field: "Manufacturer_Part_#", header: "Manufacturer_Part #", visible: true },
        { field: "Storeroom", header: "Storeroom", visible: true },
        { field: "Current_Balance", header: "Current Balance", visible: true },
        { field: "ABC_Type", header: "ABC Type", visible: true },
        { field: "Site", header: "Site", visible: true },
        { field: "Status", header: "Status", visible: true },
        { field: "Matched_system_data", header: "Matched_system_data", visible: true, body: matchedRecords },
    ])
    console.log(matched)
    return (
        <Box sx={{ display: "flex", gap: "16px", flexDirection: "row", backgroundColor: "grey", height: "100vh", width: "100%" }}>
            <Box sx={{ width: "18%", backgroundColor: "white", padding: "20px", borderRadius: "10px", height: "90%", margin: "35px", gap: "30px", display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", gap: "20px" }}>
                    <Box onClick={() => { fileRef.current && fileRef.current.click() }}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        sx={{ width: "200px", backgroundColor: "grey", padding: "20px", borderRadius: "5px", cursor: "pointer" }}>
                        <input ref={fileRef} type="file" accept=".csv,.xlsx" style={{ display: "none" }} onChange={(e) => { handleFiletoJson(e) }} />
                        <Typography sx={{ color: "#FFF", fontWeight: "600", fontSize:"12px" }}>Customer Parts File</Typography>
                    </Box>
                    <Box onClick={() => { fileRef2.current && fileRef2.current.click() }}
                        onDrop={handleDropsecond}
                        onDragOver={handleDragOverSecond}
                        sx={{ width: "200px", backgroundColor: "grey", padding: "20px", borderRadius: "5px", cursor: "pointer" }}>
                        <Typography sx={{ color: "#FFF", fontWeight: "600", fontSize:"12px" }}>BP Parts File</Typography>
                        <input ref={fileRef2} type="file" accept=".csv,.xlsx" style={{ display: "none" }} onChange={(e) => { handleSecondFileUpload(e) }} />
                    </Box>
                </Box>
                <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
                    <button style={{ background: "green", color: "#FFF", width: "100%", height: "60px", border: "none", borderRadius: "4px", fontWeight: "700", cursor: "pointer" }} onClick={() => handleSubmit()}>{isloading? <CircularProgress color="inherit" size={30}/> : "Upload Your Files"}</button>
                    <button disabled={response.length != 0 ? false : true} style={{ background: response.length != 0 ? "green" : "grey", color: "#FFF", width: "100%", height: "60px", border: "none", borderRadius: "4px", fontWeight: "700", cursor: response.length != 0 ? "pointer" : "not-allowed" }} onClick={() => handleCompare()}>{iscloading? <CircularProgress color="inherit" size={30}/> : "Compare"}</button>
                </Box>
            </Box>
            <Box sx={{ width: "78%", backgroundColor: "#FFF", padding: "20px", borderRadius: "10px", height: "90%", margin: "35px", overflow: "hidden" }}>
                <DataTable value={matched} paginator rows={8}  dataKey="field" style={{ backgroundColor: "#F1F5F9", borderRadius: "10px" }} scrollable scrollHeight="800px" columnResizeMode="expand" removableSort resizableColumns
                    emptyMessage="No customers found." >
                    {/* {columnVisible.filter(col => col.visible).map(col => <Column style={{ width: "500px",height:"50px",padding:"12px",border:"1px solid #454545"}}  field={col.field}  header={col.header} body={col.body} alignFrozen="left" />)} */}
                    <Column style={{ width: "25%", height: "50px", padding: "12px", border: "1px solid #454545" }} field="Customer_part_number" header="Part No" alignFrozen="left" />
                    <Column style={{ width: "25%", height: "50px", padding: "12px", border: "1px solid #454545" }} field="Description" header="Description" alignFrozen="left" align="top"/>
                    {/* <Column style={{ width: "500px", height: "50px", padding: "12px", border: "1px solid #454545" }} field="Manufacturer" header="Manufacturer" alignFrozen="left" />
                    <Column style={{ width: "500px", height: "50px", padding: "12px", border: "1px solid #454545" }} field="Manufacturer_Part_#" header="Manufacturer_Part_" alignFrozen="left" />
                    <Column style={{ width: "500px", height: "50px", padding: "12px", border: "1px solid #454545" }} field="Storeroom" header="Storeroom" alignFrozen="left" />
                    <Column style={{ width: "500px", height: "50px", padding: "12px", border: "1px solid #454545" }} field="Current_Balance" header="Current_Balance" alignFrozen="left" />
                    <Column style={{ width: "500px", height: "50px", padding: "12px", border: "1px solid #454545" }} field="ABC_Type" header="ABC_Type" alignFrozen="left" />
                    <Column style={{ width: "500px", height: "50px", padding: "12px", border: "1px solid #454545" }} field="Site" header="Site" alignFrozen="left" />
                    <Column style={{ width: "500px", height: "50px", padding: "12px", border: "1px solid #454545" }} field="Status" header="Status" alignFrozen="left" /> */}
                    <Column style={{ width: "50%",  border: "1px solid #454545" }} header="Matches" body={matchedRecords}  >


                    </Column>
                </DataTable>
            </Box>
        </Box>
    )
}